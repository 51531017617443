<template>
  <div id="itinerary">
    <div class="editorjs-header">
      <div class="editorjs-heading editorjs-container-—lg">
        <div>
          <h1 class="title-xl">{{ $t('editorjs.itinerary_editing') }}</h1>
          <p class="text-grey">{{ mainStore.guest.name }} - {{ prettyFrom }} → {{ prettyTo }}</p>
        </div>
        <a href="javascript:void(0)" @click="handleClose">
          <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"
            class="close-menu">
            <path class="cls-1"
              d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
              transform="translate(0 0)" fill="currentColor" />
          </svg>
        </a>
      </div>

      <Header />
    </div>

    <div class="editorjs-container--sm" :style="{ 'font-family': mainStore.font + '!important' }">
      <div class="editorjs-template-container">
        <div class="editorjs-template">
          <Intro />
          <DateSection v-for="(  items, date  ) in   mainStore.itemsByDate  " :key="date" :date="date">
            <Item v-for="  item   in   items  " :key="item.id" :item="item" />
          </DateSection>
          <Signature />
        </div>
      </div>
    </div>
    <!--I keep modal for the operation of the boostrap modal-->
    <div id="preventClosingModal" tabindex="-1" class="modal">
      <div class="popup__container">
        <div class="popup__item">
          <div class="popup__close-container">
            <button class="popup__close-btn" data-dismiss="modal" aria-label="Close">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <h5 class="text-left bottom--md">{{ $t('editorjs.modal.exit') }}</h5>
          <p class="text-left bottom--md">
            {{ $t('editorjs.modal.warning') }}
          </p>
          <div class="popup__buttons">
            <button class="popup__button" :disabled="mainStore.saving" @click="redirectToLastLocation">
              <span v-if="mainStore.saving" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              {{ $t('editorjs.modal.label_exit') }}</button>
            <button class="popup__button --green" :disabled="mainStore.saving" @click="saveAndClose">
              <span v-if="mainStore.saving" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              {{ $t('editorjs.modal.label_save_exit') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Item from './Item.vue'
import Header from './Header.vue'
import Intro from './Intro.vue'
import DateSection from './DateSection.vue'
import Signature from './Signature.vue'
import { useMainStore } from './store'
import { mapStores } from 'pinia'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import 'bootstrap/js/src/modal.js'
export default {
  components: {
    Item,
    Header,
    Intro,
    DateSection,
    Signature
  },
  computed: {
    ...mapStores(useMainStore),
    prettyFrom() {
      if (!this.mainStore.current_stay.start_date) return
      return format(new Date(this.mainStore.current_stay.start_date), 'd/M/Y', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    },
    prettyTo() {
      if (!this.mainStore.current_stay.end_date) return
      return format(new Date(this.mainStore.current_stay.end_date), 'd/M/Y', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    }
  },
  created() {
    this.mainStore.fetchItems()
    this.mainStore.fetchI18n()
  },
  mounted() {
    App.cable.subscriptions.create(
      { channel: 'ItineraryChannel', id: this.mainStore.itineraryId },
      {
        received: data => {
          this.mainStore.printing = false
        }
      }
    )
  },
  methods: {
    redirectToLastLocation() {
      this.mainStore.saving = true
      window.location.pathname = `hotels/${this.mainStore.hotel.friendly_id}/service/guests/${this.mainStore.guest.id}/bookings`
    },
    async saveAndClose() {
      await this.mainStore.save()
      this.redirectToLastLocation()
    },
    handleClose() {
      this.mainStore.dirty ? $('#preventClosingModal').modal('show') : this.redirectToLastLocation()
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/stylesheets/config/colors';

// graphical charter of guest
$primary: #85754e;
$secondary: rgba($color: $primary,
    $alpha: 0.1);

// Header
#itinerary {
  background-color: ghostwhite;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}

// Margins
#itinerary {
  .bottom--md {
    margin-bottom: 20px;
  }
}

#itinerary {
  .editorjs-header {
    position: sticky;
    top: 0;
    background: $white;
    box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
    z-index: 999;
  }

  // Containers
  .editorjs-container-—lg {
    width: 100%;
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  .editorjs-container--sm {
    width: 100%;
    max-width: 938px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  .editorjs-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 43px;
    padding-bottom: 20px;
  }

  .add-bookings-container {
    max-height: 300px;
    overflow: auto;
    margin-top: 20px;
  }

  .editorjs-container--border {
    border-top: 1px solid $white-darker-2;
  }

  .editorjs-actions {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .editorjs-actions__btns {
    column-gap: 5px;
  }

  .editorjs-tags__select select:disabled {
    color: $grey-lighter;
    opacity: 1;

    &:hover {
      color: $grey-lighter;
    }
  }

  // Fin Containers

  .editorjs-actions__group {
    display: flex;
    align-items: center;
    column-gap: 23px;

    div:not(:last-child) {
      padding-right: 23px;
    }

    .editorjs-actions-lang-dd {
      background-color: $white-darker-1;
      border: 0;
      border-radius: 5px;
      padding: 6px 10px 6px 10px;
      cursor: pointer;
    }
  }

  // Dropdowns
  .editorjs-actions-dd-menu {
    background-color: $white;
    margin: 0;
    padding: 20px 16px 20px 16px;
    border-radius: 0;
    box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
    top: 16px !important;

    a {
      text-align: right;
      padding: 0;
    }

    button {
      padding: 0;
    }

    button:not(:last-child) {
      margin-bottom: 10px;
    }

    .close-menu {
      width: 12px;
    }
  }

  .editorjs-shared-dd-menu {
    background-color: $white;
    margin: 0;
    padding: 20px 16px 20px 16px;
    border-radius: 0;
    box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
    top: 8px !important;

    .dropdown-item {
      padding: 0;
    }

    .dropdown-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .editorjs-tags__select {
    select {
      background: transparent;
      color: $purple;
      border: 0;
    }

    select:hover {
      color: $dark-purple;
      cursor: pointer;
    }

    select optgroup {
      font-style: normal;
      color: $grey;
      background-color: $white;
      text-transform: uppercase;
    }

    select option {
      color: $black;
      background-color: $white;
      text-transform: none;
      padding: 0;
    }

    select option:disabled {
      color: $grey-lighter;
    }
  }

  //Fin Dropdowns
}

// Buttons
#itinerary {
  .btn.btn--ghost {
    color: $purple;
    background-color: $purple-ghost;
    border-color: $purple-ghost;

    &:hover {
      background-color: $purple-ghost-darker;
      border-color: $purple-ghost-darker;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn.btn--purple {
    color: $white;
    background-color: $purple;
    border-color: $purple;

    &:hover {
      background-color: $dark-purple;
      border-color: $dark-purple;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn.btn--green {
    color: $white;
    background-color: $green;
    border-color: $green;

    &:hover {
      background-color: $green-darker;
      border-color: $green-darker;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .close-menu {
    display: inline-block;
    width: 14px;
    height: 14px;
    color: $black;

    &:hover {
      color: $purple;
    }
  }

  //Buttons disabled
  .btn.btn--ghost.disabled {
    color: $grey-lighter;
    background-color: $white-darker-2;
    border-color: $white-darker-2;
  }

  .btn.btn--purple:disabled,
  .btn.btn--green:disabled {
    color: $grey-lighter;
    background-color: $white-darker-2;
    border-color: $white-darker-2;
  }
}

// Fin Header

// Body
// Container
#itinerary {
  .editorjs-template-container {
    padding-top: 35px;
    padding-bottom: 35px;

    a {
      color: black;
      text-decoration: underline;
    }
  }

  .editorjs-template {
    background-color: $white;
    padding: 56px 50px 56px 50px;
    box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
  }

  .editorjs-template__header {
    display: flex;
    align-items: center;
    column-gap: 80px;
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid $white-darker-3;
  }

  .editorjs-template__header-dates {
    display: flex;
    align-items: flex-end;
    column-gap: 20px;
    margin-top: 10px;
  }
}

// Fin Container

#itinerary {
  .editorjs-template__intro textarea {
    box-shadow: none;
    border: 1px solid $white-darker-3;
  }

  .editorjs-template__intro {
    word-break: break-word;
    &-txt {
      padding: 0 15px 21px 15px;
      margin-right: -15px;
      margin-left: -15px;
    }

    &-txt:hover {
      border: 1px solid $white-darker-3;
      border-radius: 5px;
    }
  }

  .editorjs-template__header {
    &-logo {
      max-width: 250px;
    }

    &-suptitle {
      font-size: 0.8rem;
      font-weight: 500;
      color: $grey-lighter;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 500;
      color: $black;
    }
  }

  .editorjs-template__header-dates {
    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: $grey-lighter;
    }

    p {
      font-weight: 500;
      color: $primary;
    }

    .line {
      height: 1px;
      width: 15px;
      background-color: $grey-lighter;
      margin-bottom: 11px;
    }
  }

  .editorjs-template__header-dates-icon {
    display: inline;
    width: 14px;
    color: $grey-lighter;
    margin-right: 7px;
  }
}

@media (max-width: 992px) {
  #itinerary {
    .editorjs-template__header-dates {
      column-gap: 10px;
    }
  }
}

@media (max-width: 768px) {
  #itinerary {
    .editorjs-template__header-dates {
      flex-direction: column;
      row-gap: 16px;

      .line {
        display: none;
      }
    }
  }
}

// Debut Design Item
.editorjs-template__events-day {
  color: $primary;
  background-color: $secondary;
  padding: 17px 20px 17px 20px;
  margin-top: 24px;
  margin-bottom: 14px;

  &-text {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

#itinerary {
  .editorjs-template__event {
    display: flex;
    position: relative;
    column-gap: 28px;
    padding: 18px 15px 18px 15px;
    margin-right: -15px;
    margin-left: -15px;

    &-title {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 14px;
    }

    &-times {
      font-size: 1.2rem;
      font-weight: 500;
      color: $primary;
      white-space: nowrap;
      margin-top: -3px;
      width: 60px;
    }

    &.bordered {
      border: 1px solid $white-darker-3;
      border-radius: 5px;
    }

    &-actions {
      z-index: 2;
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 6px 12px;
      background-color: white;
      box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
      border: 1px solid $white-darker-3;
      border-radius: 5px;
    }
  }

  .editorjs-template__event-text {
    max-width: 45em;
    width: 100%;
    word-break: break-word;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .form__input-photo--new {
    margin-top: 15px;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .editorjs-item__photo {
    position: relative;

    .form__input-photo-preview {
      &::before {
        content: '';
      }

      margin-top: 20px;
      width: 100%;
      height: 196px;

      &:hover {
        .img-edit__icon-link {
          display: block;
          z-index: 2;
        }
      }
    }

    .img-edit__icon-link {
      display: none;
      color: #fff;
      margin: 0 4px;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 36px;
      border-radius: 5px;
      font-size: 18px;

      &:hover {
        background-color: $purple;
      }
    }
  }

  // Event icons
  .editorjs-template__event-actions {
    column-gap: 5px;
    .event-icon {
      cursor: pointer;
      width: 16px;
    }

    .event-icon:not(:last-child) {
      margin-right: 5px;
    }

    .event-edit {
      color: $purple;

      &:hover {
        color: $dark-purple;
      }
    }

    .event-delete {
      color: $red;

      &:hover {
        color: $red-dark;
      }
    }

    .event-valid {
      color: $green;

      &:hover {
        color: $green-darker;
      }
    }
  }
}

// Fin Body

// Body change running
.item__photo {
  width: 100%;
}

.item__photo.--active {
  margin-top: 20px;
  height: 196px;
  background-size: cover;
  background-position: center;
  display: block;

}

.editorjs-item__content {
  width: 100%;

  &-input {
    font-size: 1.2rem;
    border: 1px solid $white-darker-3;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;

    .codex-editor {
      border: none !important;
    }
  }

  &-input:focus {
    box-shadow: none;
  }

  &-input:focus-visible {
    border: 1px solid $purple;
    outline: 0;
  }

  .ce-block a {
    text-decoration: underline;
    color: black;
  }

  // editor inline tools
  .ce-inline-toolbar__dropdown-content svg {
    width: 12px !important;
    height: 12px !important;
    stroke-width: 0px;
  }

  .ce-inline-tool[data-tool='underline'] svg {
    transform: scale(0.7);
  }

  .ce-inline-tool {
    stroke-width: 0px;
  }

  .ce-toolbar__plus {
    color: $purple;

    &:hover {
      background-color: $purple-ghost;
      color: $dark-purple;
    }
  }

  .vendor_category_merge_tag {
    color: $purple !important;
    background-color: $purple-ghost !important;
    padding-right: 4px;
    padding-left: 4px;
  }

  .ce-toolbar__settings-btn {
    color: $purple;

    &:hover {
      background-color: $purple-ghost;
      color: $dark-purple;
    }
  }

  .cdx-search-field {
    background-color: $secondary;

    &__icon svg {
      color: $grey-lighter;
    }

    &__input {
      color: $primary;
    }

    &__input::placeholder {
      color: $grey-lighter;
    }
  }

  .ce-popover {
    box-shadow: 0 4px 20px rgba($color: $black, $alpha: 0.1);
  }

  .ce-popover__item-icon svg {
    width: 15px;
    height: 15px;
    stroke-width: 0px;
  }

  .ce-popover__item {
    &:hover {
      background-color: $purple-ghost;
    }
  }

  // Design codex ediot with border
  .codex-editor {
    border: 1px solid #E1E2E6;
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 5px;
    word-break: break-word;
  }
}

// Modal Editor
.editorjs-modal__container {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(0, 30, 128, 0.3);
}

.editorjs-template__intro-txt.placeholder {
  color: $grey-lighter;
}

.line-separator {
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: $white-darker-3;
}
</style>
