import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from '../itineraries-editor/App.vue'
import messages from '../itineraries-editor/translate.js'

import { PiniaVuePlugin, createPinia } from 'pinia'

Vue.use(PiniaVuePlugin)
Vue.use(VueI18n)
const pinia = createPinia()

const elem = document.getElementById('itineraries-editor-app')

if (elem) {
  const i18n = new VueI18n({
    locale: elem.dataset.locale, // set locale
    messages // set locale messages
  })
  
  const app = new Vue({
    el: elem,
    pinia,
    i18n,
    render: h => h(App)
  })
}

export default pinia
