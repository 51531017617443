import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    dirty: false,
    saving: false,
    printing: false,
    itineraryId: Number(window.location.pathname.split('/').slice(-1)[0]),
    editingIntro: false,
    editingItemBookingId: null,
    canInsertMergeTag: false,
    editingSignature: false,
    lang: 'fr',
    i18n: {},
    guest: {},
    current_stay: {},
    hotel: {},
    merge_tags: {},
    // updatable
    intro_fr: null,
    intro_en: null,
    signature_fr: '',
    signature_en: '',
    items: [],
    shared_on_guestapp: false,
    font: 'Hind'
  }),
  getters: {
    itemsByDate(state) {
      const itemsByDate = {}
      state.items
        .sort((a, b) => Date.parse(a.booking.datetime) - Date.parse(b.booking.datetime))
        .forEach(item => {
          if (itemsByDate[item.booking.date]) itemsByDate[item.booking.date].push(item)
          else itemsByDate[item.booking.date] = [item]
        })
      return itemsByDate
    },
    allBookingsBydate(state) {
      const bookingsByDate = {}
      state.current_stay?.eligible_bookings?.forEach(booking => {
        if (bookingsByDate[booking.date]) bookingsByDate[booking.date].push(booking)
        else bookingsByDate[booking.date] = [booking]
      })
      return bookingsByDate
    }
  },
  actions: {
    async fetchItems() {
      const response = await fetch(`/itineraries/${this.itineraryId}.json?lang=${this.lang}`)
      const itinerary = await response.json()
      itinerary.current_stay.eligible_bookings.map(booking => {
        booking.checked = itinerary.items.some(item => item.booking_id === booking.id)
        return booking
      })

      for (const key in itinerary) {
        this[key] = itinerary[key]
      }
    },
    fetchI18n() {
      fetch(`/itineraries/translations.json?lang=${this.lang}`)
        .then(response => response.json())
        .then(i18n => {
          this.i18n = i18n
        })
    },

    async shareOnGuestapp() {
      fetch(`/itineraries/${this.itineraryId}/share_on_guestapp`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
      this.shared_on_guestapp = !this.shared_on_guestapp
    },

    async updateOnGuestapp() {
      fetch(`/itineraries/${this.itineraryId}/update_on_guestapp`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
    },

    async save() {
      this.saving = true
      this.editingItemBookingId = null
      const formData = new FormData()
      formData.append('itinerary[intro_fr]', this.intro_fr)
      formData.append('itinerary[intro_en]', this.intro_en)
      formData.append('itinerary[signature_fr]', this.signature_fr ? this.signature_fr : '')
      formData.append('itinerary[signature_en]', this.signature_en ? this.signature_en : '')
      formData.append('itinerary[lang]', this.lang)
      this.items.forEach(item => {
        if (item.id) formData.append('itinerary[items_attributes][][id]', item.id)
        formData.append('itinerary[items_attributes][][booking_id]', item.booking_id)
        formData.append('itinerary[items_attributes][][title_fr]', item.title_fr)
        formData.append('itinerary[items_attributes][][title_en]', item.title_en)
        formData.append('itinerary[items_attributes][][photo]', item.photo)
        formData.append('itinerary[items_attributes][][content_fr]', item.content_fr)
        formData.append('itinerary[items_attributes][][content_en]', item.content_en)
        formData.append('itinerary[items_attributes][][delete_photo]', item.delete_photo)
      })
      await fetch(`/itineraries/${this.itineraryId}`, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: formData
      }).finally(() => {
        this.saving = false
        this.dirty = false
        this.printing = true
      })
    },
    deleteItem(bookingId) {
      this.current_stay.eligible_bookings.find(booking => booking.id === bookingId).checked = false
      this.items = this.items.filter(item => item.booking_id !== bookingId)
      this.dirty = true
    },
    async changeItems() {
      for await (const booking of this.current_stay.eligible_bookings.filter(booking => booking.checked)) {
        if (!this.items.some(item => item.booking_id === booking.id)) {
          const response = await fetch(`/itineraries/${this.itineraryId}/new_item?booking_id=${booking.id}`)
          const item = await response.json()
          this.items.push(item)
          this.dirty = true
        }
      }
      const items = this.items.filter(item => this.current_stay.eligible_bookings.some(booking => booking.id === item.booking_id && booking.checked))
      if (items.length !== this.items.length) this.dirty = true
      this.items = items
    }
  }
})
