<template>
  <div>
    <div v-click-outside="closeEditing" class="editorjs-template__intro">
      <div class="line-separator"></div>
      <div v-if="mainStore.editingSignature" id="editorjs-signature"></div>
      <div v-else class="pre-line editorjs-template__intro-txt"
        :class="{ placeholder: !mainStore[`signature_${mainStore.lang}`] }" @click="mainStore.editingSignature = true">
        <div v-for="( block, index ) in  prettyBlocks " :key="index" v-html="block"></div>
      </div>
    </div>
  </div>
</template>
<script>
import EditorJS from '@editorjs/editorjs'
import Paragraph from '@editorjs/paragraph'
import edjsHTML from 'editorjs-html'
import { useMainStore } from './store'
import { mapStores } from 'pinia'
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      editor: null
    }
  },
  computed: {
    ...mapStores(useMainStore),
    signature: {
      get() {
        return this.mainStore[`signature_${this.mainStore.lang}`]
      },
      set(value) {
        this.mainStore.dirty = true
        this.mainStore[`signature_${this.mainStore.lang}`] = value
      }
    },
    prettyBlocks() {
      const edjsParser = edjsHTML()
      const data = this.signature ? JSON.parse(this.signature) : {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: this.$t('editorjs.signature.placeholder')
            }
          }
        ]
      }
      const htmlBlocks = edjsParser.parse(data)
      return htmlBlocks
    }
  },
  watch: {
    'mainStore.editingSignature'() {
      if (this.mainStore.editingSignature) {
        this.mainStore.editingItemBookingId = null
        this.mainStore.editingIntro = null
        this.initEditor()
      } else {
        this.editor.destroy()
      }
    }
  },
  methods: {
    closeEditing(event) {
      this.mainStore.editingSignature = false
    },
    initEditor() {
      this.editor = new EditorJS({
        holder: 'editorjs-signature',
        minHeight: 20,
        data: this.signature ? JSON.parse(this.signature) : '',
        tools: {
          paragraph: {
            class: Paragraph,
            config: {
              preserveBlank: false
            }
          }
        },
        onChange: (api, event) => {
          this.editor.save().then(outputData => {
            if (outputData.blocks.length == 0) {
              this.signature = ''
            } else {
              this.signature = JSON.stringify(outputData)
            }
          })
        }
      })
    }
  }
}
</script>
