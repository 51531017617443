<template>
  <div class="editorjs-container--border">
    <div class="editorjs-container--sm editorjs-actions">
      <div class="editorjs-actions__group">
        <div>
          <select v-model="lang" class="editorjs-actions-lang-dd">
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>
        <div class="dropdown">
          <a ref="addBookings" href="javascript:void(0)" data-toggle="dropdown" class="link--purple">
            {{ $t('editorjs.add_reservation') }}
          </a>
          <div class="dropdown-menu editorjs-actions-dd-menu">
            <a href="javascript:void(0)" data-dismiss="dropdown" aria-label="Close">
              <span aria-hidden="true">
                <svg id="Calque_1" class="close-menu" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 14">
                  <path class="cls-1"
                    d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                    transform="translate(0 0)" fill="currentColor" />
                </svg>
              </span>
            </a>
            <form>
              <div class="add-bookings-container">
                <div v-for="(bookings, date) in mainStore.allBookingsBydate" :key="date">
                  <div class="form-group" href="#">
                    <div class="flex flex--align-center">
                      <input :id="'checkBooking' + date" :ref="'checkDate' + date" class="checkbox-classic"
                        type="checkbox" :checked="bookings.every(booking => booking.checked)"
                        @change="toggleChildren(bookings, $event.target.checked)" />
                      <label class="form-check-label" :for="'checkBooking' + date">
                        <b>{{ formatLongDate(date) }}</b>
                      </label>
                    </div>
                  </div>
                  <div v-for="(booking, index) in bookings" :key="index" class="form-group" href="#">
                    <div class="flex flex--align-center">
                      <input :id="'checkBooking' + booking.id" v-model="booking.checked" class="checkbox-classic"
                        type="checkbox" />
                      <label class="form-check-label" :for="'checkBooking' + booking.id">
                        {{ formatOnlyHour(booking.datetime) }} - {{ booking.vendor_category.name }} - {{
                          booking.vendor.name }} - ({{
    $t(`editorjs.booking_status.${booking.status}`)
  }})
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__button-container form__button-container--itinerary">
                <div class="link-cancel form__button btn-plain btn-lite-purple" @click.prevent="closeDropdown">
                  <button class="btn">{{ $t('editorjs.to_cancel') }}</button>
                </div>
                <div class="form__button form__button--purple btn-plain" @click.prevent="submit">
                  <button class="btn">{{ $t('editorjs.save') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div v-if="mainStore.editingIntro" ref="mergeTagSelect" class="merge-tag-select editorjs-tags__select">
          <select v-if="mainStore.i18n.merge_tags" class="intro-select" @change="insertMergeTag">
            <option value="" class="intro-select" disabled selected>{{ $t('editorjs.insert_variable') }}</option>
            <optgroup :label="mainStore.i18n.merge_tags.guest">
              <option class="intro-select" :value="`civility_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.civility }}
              </option>
              <option class="intro-select" :value="`greeting_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.greeting }}
              </option>
              <option class="intro-select" value="last_name">{{ mainStore.i18n.merge_tags.last_name }}</option>
              <option class="intro-select" value="first_name">{{ mainStore.i18n.merge_tags.first_name }}</option>
            </optgroup>
          </select>
        </div>
        <div v-if="mainStore.editingItemBookingId && mainStore.canInsertMergeTag"
          class="merge-tag-select editorjs-tags__select">
          <MergeTagSelect ref="mergeTagSelect" :lang="lang" />
        </div>
      </div>

      <div class="editorjs-actions__btns flex align-items--center">
        <a :href="editLink" :class="{ disabled: mainStore.dirty || mainStore.printing }" class="btn btn--ghost"
          data-remote="true" data-action="click->modal#openCloseModalForm">
          <span v-if="mainStore.printing" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <i v-else class="fa-solid fa-gear"></i>
        </a>
        <a :href="downloadLink" :class="{ disabled: mainStore.dirty || mainStore.printing }" class="btn btn--ghost">
          <span v-if="mainStore.printing" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <i v-else class="fa-solid fa-download"></i>
        </a>
        <a :href="viewLink" target="_blank" :class="{ disabled: mainStore.dirty || mainStore.printing }"
          class="btn btn--ghost">
          <span v-if="mainStore.printing" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <i v-else class="fa-solid fa-print"></i>
        </a>
        <!-- Lot 4 -->
        <div class="dropdown">
          <button class="btn btn--purple dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false"
            :disabled="mainStore.dirty || mainStore.printing">
            <span v-if="mainStore.printing" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            {{ $t('editorjs.shared') }}
          </button>
          <div class="dropdown-menu editorjs-shared-dd-menu">
            <button v-if="mainStore.shared_on_guestapp" class="dropdown-item" :disabled="mainStore.dirty"
              @click.prevent="mainStore.updateOnGuestapp">
              {{ $t('editorjs.update_on_app') }}
            </button>
            <button v-else class="dropdown-item" :disabled="mainStore.dirty" @click.prevent="mainStore.shareOnGuestapp">
              {{ $t('editorjs.share_on_app') }}
            </button>
            <button @click.prevent="sendItinerary" class="dropdown-item" :disabled="mainStore.dirty">
              {{ $t('editorjs.send') }}
            </button>
            <!-- <button class="dropdown-item" disabled="true">{{ $t('editorjs.update_and_send') }}</button> -->
          </div>
        </div>
        <button class="btn btn--green" :disabled="!mainStore.dirty" @click.prevent="mainStore.save">
          <span v-if="mainStore.saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ $t('editorjs.save') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useMainStore } from './store'
import { mapStores } from 'pinia'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import MergeTagSelect from './MergeTagSelect.vue'
import { itinerarySender } from './utils'

export default {
  components: {
    MergeTagSelect
  },
  computed: {
    ...mapStores(useMainStore),
    allBookings() {
      return this.mainStore?.stay?.eligible_bookings
    },
    itemExists() {
      return booking_id => this.mainStore?.items?.find(item => item.booking_id === booking_id)
    },
    downloadLink() {
      return window.location.href + '.pdf?mode=download'
    },
    viewLink() {
      return window.location.href + '.pdf?mode=preview'
    },
    editLink() {
      return window.location.href + '/edit'
    },
    lang: {
      get() {
        return this.mainStore.lang
      },
      set(value) {
        this.mainStore.lang = value
        this.mainStore.dirty = true
      }
    }
  },
  methods: {
    submit() {
      this.mainStore.changeItems()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.addBookings.click()
    },
    toggleChildren(bookings, boolean) {
      bookings.forEach(booking => {
        booking.checked = boolean
      })
    },
    async sendItinerary() {
      itinerarySender(this.mainStore.itineraryId)
    },
    insertMergeTag(e) {
      const cursor = window.getSelection()
      if ($(cursor.anchorNode).parents('.codex-editor__redactor').length === 0) return
      if (cursor.getRangeAt && cursor.rangeCount) {
        const range = cursor.getRangeAt(0)
        if (range.startContainer?.parentElement?.classList?.contains('vendor_category_merge_tag')) {
          range.startContainer.parentElement.remove()
        }
        range.insertNode(this.mergeTag(e.target.value))
      }
      e.target.value = ''
    },
    mergeTag(name) {
      const mergetag = document.createElement('span')
      mergetag.classList.add('vendor_category_merge_tag')
      mergetag.setAttribute('contenteditable', 'false')
      mergetag.innerText = name
      return mergetag
    },
    formatLongDate(date) {
      return format(new Date(date), 'iiii  dd MMMM yyyy', {
        locale: this.$i18n.locale === 'fr' ? fr : null
      })
    },
    formatOnlyHour(date) {
      return format(new Date(date), 'HH:mm')
    },
    insertMergeTag(e) {
      const cursor = window.getSelection()
      if ($(cursor.anchorNode).parents('.codex-editor__redactor').length === 0) return
      if (cursor.getRangeAt && cursor.rangeCount) {
        const range = cursor.getRangeAt(0)
        if (range.startContainer?.parentElement?.classList?.contains('vendor_category_merge_tag')) {
          range.startContainer.parentElement.remove()
        }
        range.insertNode(this.mergeTag(e.target.value))
      }
      e.target.value = ''
    },
    mergeTag(name) {
      const mergetag = document.createElement('span')
      mergetag.classList.add('vendor_category_merge_tag')
      mergetag.setAttribute('contenteditable', 'false')
      mergetag.innerText = name + " "
      return mergetag
    }
  }
}
</script>
