<template>
  <select v-if="mainStore.i18n.merge_tags" @change="insertMergeTag">
    <option value="" disabled selected>{{ $t('editorjs.insert_variable') }}</option>
    <optgroup :label="mainStore.i18n.merge_tags.vendor" class="optgroup-label">
      <option value="vendor_phone">{{ mainStore.i18n.merge_tags.vendor_phone }}</option>
      <option value="vendor_name">{{ mainStore.i18n.merge_tags.vendor_name }}</option>
      <option v-if="currentItem.merge_tags.vendor_email" value="vendor_email">{{ mainStore.i18n.merge_tags.vendor_email }}</option>
      <option value="vendor_address">{{ mainStore.i18n.merge_tags.vendor_address }}</option>
    </optgroup>
    <optgroup :label="mainStore.i18n.merge_tags.guest">
      <option :value="`civility_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.civility }}</option>
      <option :value="`greeting_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.greeting }}</option>
      <option value="last_name">{{ mainStore.i18n.merge_tags.last_name }}</option>
      <option value="first_name">{{ mainStore.i18n.merge_tags.first_name }}</option>
    </optgroup>
    <optgroup :label="mainStore.i18n.merge_tags.booking">
      <option value="date">{{ mainStore.i18n.merge_tags.date }}</option>
      <option value="hour">{{ mainStore.i18n.merge_tags.hour }}</option>
      <option value="ending_date">{{ mainStore.i18n.merge_tags.ending_date }}</option>
      <option value="ending_hour">{{ mainStore.i18n.merge_tags.ending_hour }}</option>
      <option value="category">{{ mainStore.i18n.merge_tags.category }}</option>
      <option v-if="currentItem.merge_tags.price" value="price">{{ mainStore.i18n.merge_tags.price }}</option>
      <option v-if="currentItem.merge_tags.deposit" value="deposit">{{ mainStore.i18n.merge_tags.deposit }}</option>
      <option v-if="currentItem.merge_tags.client_note" value="client_note">{{ mainStore.i18n.merge_tags.client_note }}</option>
      <option v-if="currentItem.merge_tags.persons_number" value="persons_number">{{ mainStore.i18n.merge_tags.persons_number }}</option>
      <option v-if="`currentItem.merge_tags.terms_and_conditions_${mainStore.lang}`" :value="`terms_and_conditions_${mainStore.lang}`">
        {{ mainStore.i18n.merge_tags.terms_and_conditions }}
      </option>
      <option :value="`service_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.service }}</option>
      <template v-if="currentItem.is_transportation">
        <option value="vehicle_type">{{ mainStore.i18n.merge_tags.vehicle_type }}</option>
        <option :value="`luggages_number`">{{ mainStore.i18n.merge_tags.luggages_number }}</option>
        <option value="address_pickup">{{ mainStore.i18n.merge_tags.address_pickup }}</option>
        <option value="address_destination">{{ mainStore.i18n.merge_tags.address_destination }}</option>
        <option :value="`travel_info_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.travel_info }}</option>
      </template>
      <template v-if="currentItem.is_cruise">
        <option value="cabin_category">{{ mainStore.i18n.merge_tags.cabin_category }}</option>
        <option :value="`boat_name`">{{ mainStore.i18n.merge_tags.boat_name }}</option>
        <option value="boat_itinerary">{{ mainStore.i18n.merge_tags.boat_itinerary }}</option>
      </template>
      <template v-if="currentItem.is_hotel">
        <option value="children_number">{{ mainStore.i18n.merge_tags.children_number }}</option>
        <option :value="`adult_number`">{{ mainStore.i18n.merge_tags.adult_number }}</option>
        <option value="price_per_night">{{ mainStore.i18n.merge_tags.price_per_night }}</option>
        <option value="room_category">{{ mainStore.i18n.merge_tags.room_category }}</option>
        <option value="benefits">{{ mainStore.i18n.merge_tags.benefits }}</option>
        <option value="programme_benefits">{{ mainStore.i18n.merge_tags.programme_benefits }}</option>
      </template>
      <template v-if="currentItem.is_flight_transport">
        <option value="route">{{ mainStore.i18n.merge_tags.route }}</option>
        <option value="booking_reference">{{ mainStore.i18n.merge_tags.booking_reference }}</option>
        <option :value="`passengers_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.passengers }}</option>
      </template>
      <template v-if="currentItem.is_boat_rental">
        <option value="pick_up">{{ mainStore.i18n.merge_tags.pick_up }}</option>
        <option value="return_place">{{ mainStore.i18n.merge_tags.return_place }}</option>
        <option value="boat_category">{{ mainStore.i18n.merge_tags.boat_category }}</option>
      </template>
      <template v-if="currentItem.is_ski_pass">
        <option :value="`ski_pass_list_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.ski_pass_list }}</option>
      </template>
      <template v-if="currentItem.is_ski_rental">
        <option value="equipment_list">{{ mainStore.i18n.merge_tags.ski_rental_list }}</option>
      </template>
      <template v-if="currentItem.is_babysitter">
        <option value="children_list">{{ mainStore.i18n.merge_tags.children_list }}</option>
        <option value="children_languages">{{ mainStore.i18n.merge_tags.children_languages }}</option>
      </template>
      <template v-if="currentItem.is_private_chef">
        <option value="children_number">{{ mainStore.i18n.merge_tags.children_number }}</option>
        <option value="adult_number">{{ mainStore.i18n.merge_tags.adult_number }}</option>
        <option :value="`meal_${mainStore.lang}`">{{ mainStore.i18n.merge_tags.meal }}</option>
      </template>
      <template v-if="currentItem.is_ski_courses">
        <option value="discipline">{{ mainStore.i18n.merge_tags.discipline }}</option>
        <option value="ski_lesson_list">{{ mainStore.i18n.merge_tags.ski_lesson_list }}</option>
      </template>
      <template v-if="currentItem.is_massage">
        <option value="beneficiary">{{ mainStore.i18n.merge_tags.beneficiary }}</option>
        <option value="practitioner">{{ mainStore.i18n.merge_tags.practitioner }}</option>
      </template>
    </optgroup>
  </select>
</template>

<script>
import { useMainStore } from './store'
import { mapStores } from 'pinia'

export default {
  props: {
    lang: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useMainStore),
    currentItem() {
      return this.mainStore.items.find(item => item.booking_id === this.mainStore.editingItemBookingId)
    }
  },
  methods: {
    insertMergeTag(e) {
      const cursor = window.getSelection()
      if ($(cursor.anchorNode).parents('.codex-editor__redactor').length === 0) return
      if (cursor.getRangeAt && cursor.rangeCount) {
        const range = cursor.getRangeAt(0)
        if (range.startContainer?.parentElement?.classList?.contains('vendor_category_merge_tag')) {
          range.startContainer.parentElement.remove()
        }
        range.insertNode(this.mergeTag(e.target.value))
      }
      e.target.value = ''
    },
    mergeTag(name) {
      const mergetag = document.createElement('span')
      mergetag.classList.add('vendor_category_merge_tag')
      mergetag.setAttribute('contenteditable', 'false')
      mergetag.innerText = name
      if (['travel_info', 'number_luggages', 'number_pers', 'terms_and_conditions', 'breakfast', 'benefits'].includes(name)) {
        mergetag.innerText += `_${this.mainStore.lang}`
      }
      return mergetag
    }
  }
}
</script>

<style></style>
