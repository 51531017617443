<template>
  <div>
    <div class="editorjs-template__header">
      <div v-if="mainStore.show_logo" class="editorjs-template__header-logo">
        <img v-if="mainStore.show_logo" :src="mainStore.logo.url" class="img-fluid" />
      </div>
      <div class="editorjs-template__header-content">
        <h2 class="editorjs-template__header-suptitle">
          {{ mainStore.lang === 'fr' ? 'VOTRE ITINÉRAIRE' : 'YOUR ITINERARY' }}
        </h2>
        <h3 v-if="mainStore.guest" class="editorjs-template__header-title">{{ mainStore.guest.name }}</h3>
        <div v-if="mainStore.current_stay" class="editorjs-template__header-dates">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="editorjs-template__header-dates-icon">
              <path
                d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                fill="currentColor" />
            </svg>
            <span>{{ mainStore.lang === 'fr' ? 'DU' : 'FROM' }}</span>
            <p :style="{ color: mainStore.hotel.primary_color }">{{ prettyFrom }}</p>
          </div>
          <div class="line"></div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="editorjs-template__header-dates-icon">
              <path
                d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
                fill="currentColor" />
            </svg>
            <span>{{ mainStore.lang === 'fr' ? 'AU' : 'TO' }}</span>
            <p :style="{ color: mainStore.hotel.primary_color }">{{ prettyTo }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-click-outside="closeEditing" class="editorjs-template__intro">
      <div id="editorjs-intro" v-if="mainStore.editingIntro"></div>
      <div v-else class="pre-line editorjs-template__intro-txt" @click="mainStore.editingIntro = true">
        <div v-for="(block, index) in prettyBlocks" :key="index" v-html="block"></div>
      </div>
    </div>
  </div>
</template>
<script>
import EditorJS from '@editorjs/editorjs'
import Paragraph from '@editorjs/paragraph'
import { MergeTag } from 'controllers/editorJs/mergeTag'
import edjsHTML from 'editorjs-html'
import { useMainStore } from './store'
import { mapStores } from 'pinia'
import vClickOutside from 'v-click-outside'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      editor: null
    }
  },
  computed: {
    ...mapStores(useMainStore),
    intro: {
      get() {
        return this.mainStore[`intro_${this.mainStore.lang}`]
      },
      set(value) {
        this.mainStore.dirty = true
        this.mainStore[`intro_${this.mainStore.lang}`] = value
      }
    },
    prettyFrom() {
      if (!this.mainStore.current_stay.start_date) return ''
      return format(new Date(this.mainStore.current_stay.start_date), 'ccc dd MMM yyyy', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    },
    prettyTo() {
      if (!this.mainStore.current_stay.end_date) return ''
      return format(new Date(this.mainStore.current_stay.end_date), 'ccc dd MMM yyyy', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    },
    prettyBlocks() {
      const edjsParser = edjsHTML()
      const data = this.intro ? JSON.parse(this.intro) : {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: ''
            }
          }
        ]
      }
      const htmlBlocks = edjsParser.parse(data)
      return htmlBlocks.map(block =>
        block.replaceAll(/<span[^>]*class="vendor_category_merge_tag"[^>]*>(.*?)<\/span>/g, (match, text) => {
          return this.mainStore.merge_tags[text] || ''
        })
      )
    }
  },
  watch: {
    'mainStore.editingIntro'() {
      if (this.mainStore.editingIntro) {
        this.mainStore.editingItemBookingId = null
        this.mainStore.editingSignature = null
        this.initEditor()
      } else {
        this.editor.destroy()
      }
    }
  },
  methods: {
    closeEditing(event) {
      if (event.target.classList.contains('intro-select')) return
      this.mainStore.editingIntro = false
    },
    initEditor() {
      this.editor = new EditorJS({
        holder: 'editorjs-intro',
        minHeight: 20,
        data: JSON.parse(this.intro),
        tools: {
          mergeTag: { class: MergeTag },
          paragraph: {
            class: Paragraph,
            config: {
              preserveBlank: true
            }
          }
        },
        onChange: (api, event) => {
          this.editor.save().then(outputData => {
            this.intro = JSON.stringify(outputData)
          })
        }
      })
    }
  }
}
</script>
