export async function itinerarySender(itineraryId) {
  await fetch(`/itineraries/${itineraryId}/send_itinerary`)
    .then(response => response.json())
    .then(data => {
      document.querySelector('.form-modal__form').innerHTML = data.form
    })
    .finally(() => {
      clearBoxVisus()
      DragNDrop()
      autosize($('.js-confirmation-message-content'))
      confirmationMessageBoxVisu()
      openCloseModalForm()
      loadSendConfirmationMessageGuestSelectize()
      controlSelectizeConfirmationChannel.removeOption('sms', false)
      controlSelectizeConfirmationChannel.setValue('email', false)
      controlSelectizeSendConfirmationLocale.trigger('change')
    })
}
