<template>
  <div v-click-outside="vcoConfig" class="editorjs-template__event" :class="{ bordered: hovered || editing }"
    @mouseenter="hovered = true" @mouseleave="hovered = false">
    <div class="editorjs-template__event-actions" :style="actionStyle">
      <!--Valid change Button-->
      <svg v-if="editing" class="event-valid event-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        @click.stop="closeEditing">
        <path v-if="editing"
          d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
          fill="currentColor" @click.stop="closeEditing" />
      </svg>
      <!--Edit Button -->
      <svg v-if="!editing" class="event-edit event-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        @click.stop="mainStore.editingItemBookingId = item.booking_id">
        <path
          d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
          fill="currentColor" />
      </svg>
      <!--Delete Button -->
      <svg class="event-delete event-icon" @click="handleDelete()" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512">
        <path
          d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
          fill="currentColor" />
      </svg>
    </div>
    <div :style="{ color: mainStore.hotel.primary_color }" class="editorjs-template__event-times">{{ prettyHour }}</div>
    <div v-if="!editing" class="editorjs-template__event-text">
      <h3 v-for="(block, index) in prettyTitle" :key="`title_${index}`" v-html="renderBlock(block)"></h3>
      <div v-for="(block, index) in prettyContent" :key="`content_${index}`" v-html="renderBlock(block)"></div>
      <section v-if="photo" :class="photo_url ? 'item__photo --active' : 'item__photo'"
        :style="`background-image: url(${photo_url})`"></section>
    </div>
    <div v-else class="editorjs-item__content">
      <div :id="`editorjs-title-${item.id}`" class="editorjs-item__content-input"></div>
      <div :id="`editorjs-${item.id}`"></div>
      <div class="editorjs-item__photo">
        <div v-if="photo_url" class="form__input-photo-preview --edit-mode"
          :style="`background-image: url(${photo_url})`">
          <a @click="handleChangePhoto" for="editorjs-item__photo-input" class="img-edit__icon-link">
            <i class="fa-solid fa-pen"></i>
          </a>
          <a class="img-edit__icon-link" @click.stop="handleDeletePhoto">
            <i class="fa-solid fa-trash-can"></i>
          </a>
        </div>
        <div v-else @click="handleChangePhoto" class="form__input-photo--new"
          :style="{ color: mainStore.hotel.primary_color }">{{ $t('editorjs.add_photo') }}</div>
        <input :id="`photoInput-${this.item.id}`" hidden type="file" class="form__input-photo"
          accept="image/png, image/jpeg" @change="handleFileUpload($event)" />
      </div>
    </div>

    <div :id="`preventDeleteModal-${item.id}`" tabindex="-1" class="modal">
      <div class="popup__container">
        <div class="popup__item">
          <div class="popup__close-container">
            <button class="popup__close-btn" data-dismiss="modal" aria-label="Close">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <p class="text-left bottom--md">
            {{ $t('editorjs.modal.remove_booking') }}
          </p>
          <div class="popup__buttons">
            <div class="popup__button" data-dismiss="modal" aria-label="Close">{{ $t('editorjs.to_cancel') }}</div>
            <div class="popup__button --red" data-dismiss="modal" aria-label="Close"
              @click="mainStore.deleteItem(item.booking_id)">
              {{ $t('editorjs.modal.remove') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import Underline from '@editorjs/underline'
import List from '@editorjs/list'
import Paragraph from '@editorjs/paragraph'
import { MergeTag } from 'controllers/editorJs/mergeTag'
import { useMainStore } from './store'
import { mapStores } from 'pinia'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import edjsHTML from 'editorjs-html'
import vClickOutside from 'v-click-outside'

let currentEditor = { title: null, content: null }

export default {
  name: 'Item',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hovered: false,
      vcoConfig: {
        handler: this.closeEditing,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false
      }
    }
  },

  computed: {
    ...mapStores(useMainStore),
    title: {
      get() {
        return this.item[`title_${this.mainStore.lang}`]
      },
      set(value) {
        this.mainStore.dirty = true
        this.item[`title_${this.mainStore.lang}`] = value
      }
    },
    content: {
      get() {
        return this.item[`content_${this.mainStore.lang}`]
      },
      set(value) {
        this.mainStore.dirty = true
        this.item[`content_${this.mainStore.lang}`] = value
      }
    },
    photo_url: {
      get() {
        return this.item.photo_url
      },
      set(value) {
        this.mainStore.dirty = true
        this.item.photo_url = value
      }
    },
    photo: {
      get() {
        return this.item.photo_url || this.item.photo
      },
      set(value) {
        this.mainStore.dirty = true
        this.item.photo = value
      }
    },
    prettyTitle() {
      return this.prettyBlocks('title')
    },
    prettyContent() {
      return this.prettyBlocks('content')
    },
    prettyHour() {
      return format(new Date(this.item.booking.datetime), 'p', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    },
    actionStyle() {
      return {
        display: this.hovered || this.editing ? 'flex' : 'none'
      }
    },
    editing() {
      return this.mainStore.editingItemBookingId === this.item.booking_id
    }
  },
  watch: {
    'mainStore.editingItemBookingId'(bookingId) {
      if (bookingId === this.item.booking_id) {
        this.initEditor(
          {
            holder: `editorjs-title-${this.item.id}`,
            data: JSON.parse(this.title)
          },
          'title'
        )
        this.initEditor(
          {
            holder: `editorjs-${this.item.id}`,
            data: JSON.parse(this.content)
          },
          'content'
        )
      }
      if (this.editing) {
        document.addEventListener('selectionchange', this.updateCanInsertMergeTag)
        this.mainStore.editingIntro = null
        this.mainStore.editingSignature = null
      } else {
        document.removeEventListener('selectionchange', this.updateCanInsertMergeTag)
      }
    }
  },
  methods: {
    initEditor(config, type) {
      currentEditor[type] = new EditorJS({
        ...config,
        minHeight: 20,
        tools: {
          mergeTag: { class: MergeTag },
          paragraph: {
            class: Paragraph,
            config: {
              preserveBlank: true
            }
          },
          underline: Underline,
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          }
        },
        onChange: (api, event) => {
          currentEditor[type].save().then(outputData => {
            this[type] = JSON.stringify(outputData)
          })
        }
      })
    },
    prettyBlocks(type) {
      const edjsParser = edjsHTML()
      const htmlBlocks = edjsParser.parse(JSON.parse(this.item[`${type}_${this.mainStore.lang}`]))
      return htmlBlocks.map(block =>
        block.replaceAll(/<span[^>]*class="vendor_category_merge_tag"[^>]*>(.*?)<\/span>/g, (match, text) => {
          if (text === 'date' || text === 'ending_date') {
            if (!this.item.merge_tags[text]) return ''
            return format(new Date(this.item.merge_tags[text]), 'cccc dd MMMM yyyy', {
              locale: this.mainStore.lang === 'fr' ? fr : null
            }).toLowerCase()
          }
          return this.item.merge_tags[text] || ''
        })
      )
    },
    middleware(event) {
      return (
        !this.mainStore.editingItemBookingId &&
        (!$(event.target).parents('.current-edited-item').length || !$(event.target).parents('.merge-tag-select').length)
      )
    },
    closeEditing() {
      this.mainStore.editingItemBookingId = null
    },
    handleDelete() {
      $(`#preventDeleteModal-${this.item.id}`).modal('show')
    },
    handleChangePhoto() {
      $(`#photoInput-${this.item.id}`).click()
    },
    handleFileUpload(event) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const imageBase64 = e.target.result
        this.photo_url = imageBase64
      }.bind(this)
      this.photo = event.target.files[0]
      this.item.delete_photo = null
      reader.readAsDataURL(event.target.files[0])
    },
    handleDeletePhoto() {
      this.photo = null
      this.photo_url = null
      this.item.delete_photo = true
      this.mainStore.dirty = true
    },
    updateCanInsertMergeTag() {
      const cursor = document.getSelection()
      this.mainStore.canInsertMergeTag =
        $(cursor.anchorNode).parents('.codex-editor__redactor').length &&
        !cursor?.baseNode?.parentElement?.classList?.contains('vendor_category_merge_tag')
    },
    renderBlock(block) {
      return block === '<p></p>' ? '<br>' : block
    }
  }
}
</script>
