export class MergeTag {
  static get isInline() {
    return true
  }

  static get sanitize() {
    return {
      span: {
        class: 'vendor_category_merge_tag',
        contenteditable: true
      }
    }
  }

  render() {}

  surround(range) {}

  checkState(selection) {}
}
