export default {
  fr: {
    editorjs: {
      add_reservation: 'Ajouter des réservations',
      insert_variable: 'Insérer une variable',
      itinerary_editing: "Édition d'itinéraire",
      save: 'Enregistrer',
      send: 'Envoyer',
      shared: 'Partager',
      to_cancel: 'Annuler',
      update_and_send: 'Mettre à jour et envoyer',
      share_on_app: 'Publier sur l’app client',
      update_on_app: 'Mettre à jour sur l’app client',
      add_photo: '+ Ajouter une image',
      modal: {
        exit: 'Quitter sans sauvegarder ?',
        label_exit: 'Quitter',
        label_save_exit: 'Sauvegarder et quitter',
        remove: 'Supprimer',
        remove_booking: "Êtes-vous sûr de vouloir supprimer cette réservation de l'itinéraire ?",
        warning:
          'Vous êtes sur le point de quitter l’éditeur sans avoir sauvegardé votre travail. Si vous ne le faites pas, l’ensemble de vos modifications seront perdues.'
      },
      booking_status: {
        pending: 'En attente',
        done: 'Confirmée',
        reconfirmed: 'Reconfirmée',
        waiting_list: 'En liste d’attente',
        full: 'Complet'
      },
      signature: {
        placeholder: 'Texte de signature vide, cliquez ici pour ajouter votre texte.'
      }
    }
  },
  en: {
    editorjs: {
      add_reservation: 'Add bookings',
      insert_variable: 'Insert a variable',
      itinerary_editing: 'Itinerary Editing',
      save: 'Save',
      send: 'Send',
      shared: 'Share',
      to_cancel: 'Cancel',
      update_and_send: 'Update and send',
      share_on_app: 'Share on guestapp',
      update_on_app: 'Update on guestapp',
      add_photo: '+ Add a picture',
      modal: {
        exit: 'Exit without saving ?',
        label_exit: 'Exit',
        label_save_exit: 'Save and exit',
        remove: 'Remove',
        remove_booking: 'Are you sure you want to remove this reservation from the itinerary ?',
        warning: "You are about to exit the editor without saving your work. If you don't, all your changes will be lost."
      },
      booking_status: {
        pending: 'Pending',
        done: 'Confirmed',
        reconfirmed: 'Reconfirmed',
        waiting_list: 'Waiting list',
        full: 'Full'
      },
      signature: {
        placeholder: 'Signature is empty, click here to add your text.'
      }
    }
  }
}
