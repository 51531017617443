<template>
  <div class="editorjs-template__events-container">
    <div :style="{ background: mainStore.hotel.secondary_color }" class="editorjs-template__events-day">
      <h2 :style="{ color: mainStore.hotel.primary_color }" class="editorjs-template__events-day-text">
        {{ prettyDate }}
      </h2>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { useMainStore } from './store'
import { mapStores } from 'pinia'

export default {
  props: {
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useMainStore),
    prettyDate() {
      return format(new Date(this.date), 'cccc dd MMMM yyyy', {
        locale: this.mainStore.lang === 'fr' ? fr : null
      })
    }
  }
}
</script>
